import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import * as dat from 'lil-gui'
import { gsap } from 'gsap';


THREE.ColorManagement.enabled = false

/**
 * Base
 */
// Debug
// const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load('/textures/matcaps/11.jpg');
const matcapTexture2 = textureLoader.load('/textures/matcaps/9.jpg');
const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture });
const material2 = new THREE.MeshMatcapMaterial({ matcap: matcapTexture2 });

// Fonts
const fontLoader = new FontLoader();
fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) => {
        const textGeometry = new TextGeometry(`Hi, Yoga here!
I create
3D Arts x Tech.`,
            {
                font,
                size: 0.5,
                height: 0.1,
                curveSegments: 6,
                bevelEnabled: true,
                bevelThickness: 0.02,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 4,

            });
        // textGeometry.computeBoundingBox()
        // textGeometry.translate(
        //     -textGeometry.boundingBox.max.x * 0.5,
        //     -textGeometry.boundingBox.max.y * 0.5,
        //     -textGeometry.boundingBox.max.z * 0.5
        // )
        textGeometry.center()
        textGeometry.computeVertexNormals()

        const text = new THREE.Mesh(textGeometry, material2);
        scene.add(text)

        gsap.to(text.rotation, { y: (Math.PI * 2) * 8, duration: 3, ease: 'power4.out' })

    }
);

// const axesHelper = new THREE.AxesHelper();
// scene.add(axesHelper)

/**
 * Object
 */
// const cube = new THREE.Mesh(
//     new THREE.BoxGeometry(1, 1, 1),
//     new THREE.MeshBasicMaterial()
// )
// scene.add(cube)

const donutGeo = new THREE.TorusGeometry(0.5, 0.3)
const coneGeo = new THREE.ConeGeometry(0.3, 0.6)
for (let i = 0; i < 100; i++) {
    const donut = new THREE.Mesh(donutGeo, material);
    const cone = new THREE.Mesh(coneGeo, material);

    const scale = Math.random() * (1 - 0.3 + 1) + 0.3

    donut.position.x = (Math.random() - 0.5) * 50
    donut.position.y = (Math.random() - 0.5) * 50
    donut.position.z = (Math.random() - 0.5) * 50
    donut.rotation.x = Math.random() * Math.PI;
    donut.rotation.y = Math.random() * Math.PI;
    donut.rotation.z = Math.random() * Math.PI;
    donut.scale.set(scale, scale, scale)

    cone.position.x = (Math.random() - 0.5) * 50
    cone.position.y = (Math.random() - 0.5) * 50
    cone.position.z = (Math.random() - 0.5) * 50
    cone.rotation.x = Math.random() * Math.PI;
    cone.rotation.y = Math.random() * Math.PI;
    cone.rotation.z = Math.random() * Math.PI;
    cone.scale.set(scale, scale, scale)

    scene.add(donut, cone);
}

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 7
camera.position.z = 10
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.outputColorSpace = THREE.LinearSRGBColorSpace
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

gsap.to(camera.position, {
    duration: 2,
    y: 0,
    z: 7,
    delay: 0
})

const tick = () => {
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()